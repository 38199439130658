
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:wght@500&display=swap');
 $primary:#2E9B84;//#7E5725; //;00CCCC
 $primary-dark:#408b7c;
 $secondary:#F6F6F6;//#C69549
 $secondary-light:#fff;
 $success:#00c9b7;
 $info: #00a0e0;;
 $warning:#E4A11B;
 $danger:#DC4C64;
 $light:#F1F3F9;
 $dark:#244f44;
 $gray:#868686;
 $contrast:#3b23c0;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
 "light":       $light,
  "dark":       $dark
);
$white: $light;
$grid-breakpoints: (
  xs: 0,
  sm: 700px,
  md: 900px,
  lg: 1200px,
  xl: 1500px,
  xxl: 2300px
);
$container-max-widths: (
  sm: 600px,
  md: 800px,
  lg: 1100px,
  xl: 1300px,
  xxl: 2000px
);
@import "../node_modules/bootstrap/scss/bootstrap";