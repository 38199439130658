@import "../../custom.scss";
.row .round-circle{
    width: 100%;
    height: 100% ;
    border-radius:50% ;
    border-style:"solid";

}



.quote-classic {
    background-color: aqua;
    font-size: 24px;
    line-height: 1.33333;
}





