@import "../custom.scss";

div {
    margin: 0;
    padding-top: 0%;
}

div.sup-area.container {

    flex-direction: row;
    padding: 0;
    margin-top: -2%;

    // mobile
    @media (max-width: 571px) {
        background-color: rgba($primary, 1);
        margin-bottom: 2%;
    }

    // tablet and desktop
    @media (min-width: 572px) {
        .desktop {
            background-color: rgba($primary, 1);
        }

        .mobile {
            display: none;
        }
    }
}