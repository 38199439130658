.site-footer {
    font-size: .9em
}

.icon-style {
    color: rgb(236, 240, 240);
    font-size: "2.5rem";
}

.columna-doble {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.icon-circle {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 38px;
}
#div_container_footer{
   
}