@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
@import "../../custom.scss";

//mOBILE
@media (max-width:571px) {

  div.package-card {
    height: 226px;
    // width: 25%;
  }
}

//transitions
.grow {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.grow:hover {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.swing:hover {
  -webkit-animation: swing 1s ease;
  animation: swing 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/// DESKTOP
@media (min-width:572px) {
  div.package-card {
    height: 256px;
    // width: 20vw; 
  }



  .button-primary {

    height: 50px;
    width: 90%;

    border: 2px solid $primary;
    /* Establece el grosor y el color del borde */
    border-radius: 20px;
    /* Define el radio de curvatura para redondear los bordes */
    background-color: $light;
    /* Establece el color de fondo del botón */
    color: $primary;
    /* Establece el color del texto dentro del botón */
    padding: 10px 10px;
    /* Añade relleno al botón para darle espacio al texto */
    text-align: center;
    /* Centra el texto horizontalmente dentro del botón */
    text-decoration: none;
    /* Quita la subrayado predeterminado en los enlaces (si el botón es un enlace) */
    display: inline-block;
    /* Hace que el botón se comporte como un elemento en línea */
    cursor: pointer;
  }

}

.container {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  justify-content: center;
  background-color: #f1f4f8;

}

.title-inicio {
  font-family: "Montserrat";
  font-weight: 700;
  color: $primary;
  // display: "flex",
  // justifyContent:"space around",
  // marginLeft:"20%",
  // marginRight:"20%",
  font-size: 1.8rem;
  padding-left: 3.2rem;
  padding-bottom: 40px;
  padding-top: 40px;
}

.numero-grande {
  font-family: "Montserrat";
  font-weight: 700;
  color: $primary;
  font-size: 2.5rem;
  padding-left: 2rem;
}

//para section 4
.address {
  p {
    font-family: "Montserrat";
    color: $primary;
    font-size: 1.5rem;
    padding-left: 2rem;
    line-height: 1rem;
  }
}

.whatsap_icon {
  color: rgb(29, 173, 84);
  font-size: 3rem;

}

img {
  height: 4rem;
}

.boton-cita {
  margin-top: 4rem;
}

.div-diente {
  box-shadow: var(--shd, 0 1px 4px rgba(0, 0, 0, .6));
  height: 300px;
  width: 500px;
  margin: auto;
  background-color: $primary;
  border-radius: 1rem;
  // p{
  //     font-size: 2.8rem;
  //     font-weight: 700;
  // }

}


.tamaño-diente {
  height: 3rem;
}




.gb-primary-img-servicios {
  //background-image: url("../../assets/img/servicios_bg.png");
  background-color: $primary;
  //background-size: cover;
  //background-size: 100vw;
  //width: 190%;
  // height: 740px;
  opacity: 1;
  padding-bottom: 5%;
  margin-bottom: 0%;
  background-size: 1900px 740px;
  height: 40vh;
}